import React from 'react';
import {graphql} from 'gatsby';
import Footer from "../../atoms/footer";
import Header from "../../atoms/header";
import * as styles from "./JobPost.module.scss";
import FreeTrial from "../../atoms/freeTrial";
import Button from "../../atoms/Button";
import {Image} from "../../../hooks/gatsbyImg";
import ApplyForm from "../../atoms/ApplyForm";

const JobPostTemplate = ({data}) => {
    return (
        <main>
            <Header />
            <div className={styles.container}>
                <div className={styles.innerContainer}>
                    <div className={styles.headerText}>
                        <p>View all open positions</p>
                        <h1>{data.wpJob.title}</h1>
                        <p>{data?.wpJob?.jobDetails?.location}</p>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.innerContent} dangerouslySetInnerHTML={{__html: data.wpJob.content}}></div>
                    </div>
                    <div className={styles.sidebar}>
                        <div className={styles.box}>
                            <Button text='Apply Now' type='orange' to='#applynow' />
                            <Button text='Share Now' type='white' to='#' extraStyle={{border: '1px solid black', color:'black'}} />
                            <p className={styles.bold}>{data?.wpJob?.jobDetails?.deadline}</p>
                            <p className={styles.bold}>Location</p>
                            <p>{data?.wpJob?.jobDetails?.location}</p>
                            <p className={styles.bold}>Work Type</p>
                            <p>{data?.wpJob?.jobDetails?.workType}</p>
                            <p className={styles.bold}>Classification</p>
                            <ul>
                                <li>{data?.wpJob?.jobDetails?.classification}</li>
                            </ul>
                        </div>
                        <Image className={styles.image} filename='single-job-1.png' height='auto' />
                    </div>
                </div>
            </div>
            <ApplyForm />
            <FreeTrial />
            <Footer />
        </main>
    );
};

export default JobPostTemplate;
export const query = graphql`
  query($id: String!) {
    wpJob(id: {eq: $id}) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      jobDetails{
        fieldGroupName
        workType
        classification
        location
        deadline
      }
    }
  }
`;