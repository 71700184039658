import React, { useState } from "react";
import axios from "axios";
import * as styles from "./ApplyForm.module.scss";
import Input from "../Input";

const ApplyForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [resume, setResume] = useState(null);
  const [textArea, setTextArea] = useState("");
  const [msg, setMsg] = useState(false);
  const [error, setError] = useState(false);
  const [validateEmail, setValidateEmail] = useState(null);
  const [validateName, setValidateName] = useState(null);

  const onChangeValue = (value, field) => {
    switch (field) {
      case "name":
        setName(value);
        const regx = /^[A-Za-z][-a-zA-Z ]+$/;
        if(value === '')
        {
          setValidateName("Name is blank");
        }
        else if(!regx.test(value)){
          setValidateName('Name must contains character only')
        }
        else{
          setValidateName(null)
        } 
        break;
      case "email":
        setEmail(value);
        if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(value))
        {
          setValidateEmail(null)
        }
        else{
          setValidateEmail('You have entered an invalid email address!')
        }
        break;
      case "subject":
        setSubject(value);
        break;
      case "resume":
        setResume(value);
        break;
      case "textarea":
        setTextArea(value);
        break;
    }
  };

  function clearForm(){
    setEmail('');
    setName('');
    setResume('');
    setSubject('');
    setTextArea('');
  }


  const handelSubmit = async (e) => {
    e.preventDefault();
    if(name && email && resume && subject){
      const data = new FormData();
    data.append("fullName", name);
    data.append("email", email);
    data.append("subject", subject);
    data.append("message", textArea);
    data.append("file", resume);

      await axios({
      method: "post",
      url: "https://live.point-us.de/wp-json/contact-form-7/v1/contact-forms/1113/feedback",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        clearForm();
        setMsg('Thanks for your application');
        setError('');
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        setMsg('');
        setError("Something went wrong");
      });
    }
    else{
      setError('Fields are empty')
    }
    
  };

  return (
    <div id="applynow" className={styles.container}>
      <div className={styles.innerContainer}>
        <form>
          <div className={styles.inputs}>
            <Input
              onChange={onChangeValue}
              label="Name"
              value={name}
              required={true}
              type="text"
              field="name"
              error={validateName}
            />
            <Input
              onChange={onChangeValue}
              label="Contact email"
              value={email}
              required={true}
              type="text"
              field="email"
              error={validateEmail}
            />
            <Input
              onChange={onChangeValue}
              label="Subject"
              value={subject}
              required={true}
              type="text"
              field="subject"
            />
            <div>
              <Input
                onChange={onChangeValue}
                type="file"
                value={resume}
                label="Resume"
                required={true}
                field="resume"
              />
            </div>
          </div>
          <Input
            onChange={onChangeValue}
            value={textArea}
            type="textarea"
            label="Tell us a bit about you"
            rows={10}
            field="textarea"
          />
          <p className={styles.terms}>
            By submitting this form you agree to our terms and conditions and
            our Privacy Policy which explains how we may collect, use and
            disclose your personal information including to third parties.
          </p>
          <button onClick={(e) => handelSubmit(e)}>Apply Now</button>
          {(msg || error) && <span className={msg ? styles.msg : styles.error}>{msg ? msg : error}</span>}
        </form>
      
      </div>
    </div>
  );
};

export default ApplyForm;
